<template>
    <div
        class="c-play"
        :class="{ 'is-playing' : isPlaying }"
    >
        <anim-text
            type="chars"
            tag="h1"
            text="There’s nothing here. Just Pong."
            class="c-play_title | c-heading -h6"
            :reveal="true"
        />

        <div class="c-play_content">
            <div class="c-play_headline">
                <anim-text
                    tag="h2"
                    text="Let's play pong!"
                    class="c-heading -h5"
                    :visible="isToStart"
                />
                <anim-text
                    tag="p"
                    text="Use your up and down arrows"
                    class="c-play_instructions | o-text"
                    :visible="isToStart && showInstructions"
                />
            </div>
            <anim-text
                tag="h2"
                text="Pause"
                class="c-play_headline | c-heading -h5"
                :visible="isPaused"
            />
            <anim-text
                tag="h2"
                text="You won!"
                class="c-play_headline | c-heading -h5"
                :visible="isWon"
            />
            <anim-text
                tag="h2"
                text="You’re too high. You lost."
                class="c-play_headline | c-heading -h5"
                :visible="isLost"
            />

            <div
                class="c-play_buttons"
                :class="{ 'is-visible' : isToStart }"
            >
                <app-button
                    label="Play"
                    display="primary"
                    :stretch="true"
                    class="c-play_button"
                    @click="start"
                />
            </div>
            <div
                class="c-play_buttons"
                :class="{ 'is-visible' : isPaused }"
            >
                <app-button
                    label="Restart"
                    :stretch="true"
                    class="c-play_button"
                    @click="reset"
                />
                <app-button
                    label="Continue"
                    display="primary"
                    :stretch="true"
                    class="c-play_button"
                    @click="resume"
                />
            </div>
            <div
                class="c-play_buttons"
                :class="{ 'is-visible' : isWon || isLost }"
            >
                <app-button
                    :label="isWon ? 'Play again' : 'Try again'"
                    display="primary"
                    :stretch="true"
                    class="c-play_button"
                    @click="reset"
                />
            </div>
        </div>
        <div class="c-play_inner">
            <canvas
                ref="canvas"
                class="c-play_canvas"
            ></canvas>
        </div>
        <div class="c-play_nav">
            <app-button
                label="Up"
                :stretch="true"
                @touchstart="moveUp"
                @touchend="keyUp"
            />
            <app-button
                label="Down"
                :stretch="true"
                @touchstart="moveDown"
                @touchend="keyUp"
            />
        </div>
    </div>
</template>

<script>

import AnimText         from '@/templates/objects/AnimText'
import AppButton        from '@/templates/components/AppButton'

import { assets }       from '@/data'
import { loadAudio }    from '@/utils/audio'

const fps = 60
const scoreToWin = 5

const COLORS = {
    ELEMENTS: '#00E2ED',
    SCORE: '#303030',
}

const GAME_STATES = {
    PLAYING: 'playing',
    START: 'start',
    PAUSE: 'pause',
    WIN: 'win',
    LOSE: 'lose',
}

export default {
    name: 'Play',
    inject: ['store'],
    components: {
        AnimText,
        AppButton,
    },
    data: () => ({
        showInstructions: true,
        ballSize: 16,
        ballVelocityX: 8,
        currentState: GAME_STATES.START,
        difficultyLevel: 1,
        paddleHeight: 100,
        paddleOneY: 250,
        paddleOneDirectionY: 0,
        paddleOneVelocityY: 15,
        paddleTwoY: 250,
        paddleTwoVelocityY: 10,
        paddleWidth: 12,
        playerOneScore: 0,
        playerTwoScore: 0,
    }),
    created() {
        this.soundPoint     = loadAudio(assets.audio.pongPoint, .3);
        this.soundPaddle    = loadAudio(assets.audio.pongPaddle, .3);
        this.soundOut       = loadAudio(assets.audio.pongOut, .2);
        this.soundWin       = loadAudio(assets.audio.pongWin, .3);
        this.soundLose      = loadAudio(assets.audio.pongLose, .2);
    },
    mounted() {

        this.$canvas = this.$refs.canvas
        this.$canvas.width = this.$canvas.offsetWidth
        this.$canvas.height = this.$canvas.offsetHeight
        this.canvasContext = this.$canvas.getContext('2d')

        this.ballPositionX = this.$canvas.width/2
        this.ballPositionY = this.$canvas.height/2

        this.gameInterval = window.setInterval(() => {})
        this.paddleOneY = this.$canvas.height/2 - this.paddleHeight/2
        this.paddleTwoY = this.$canvas.height/2 - this.paddleHeight/2
        this.ballPositionY = this.$canvas.height/2 - this.ballSize/2
        this.ballVelocityY = this.getBallVelocityX()
        this.ballVelocityY = this.getRandomNumber(-5, 5) * (.25 * this.difficultyLevel)

        this.draw();

        document.addEventListener('keydown', this.onKeyDown = e => this.keyDown(e))
        document.addEventListener('keyup', this.onKeyUp = () => this.keyUp())
        window.addEventListener('resizeEnd', this.onResize = () => this.resize())
        window.addEventListener('blur', this.onBlur = () => this.blur())
    },
    methods: {
        start() {
            this.currentState = GAME_STATES.PLAYING
            this.showInstructions = false

            this.gameInterval = window.setInterval(() => {
                this.moveEverything();
                this.draw();
            }, 1000/fps);
        },

        resume() {
            if(this.isPaused) {
                this.start()
            }
        },

        pause() {
            if(!this.isPaused) {
                this.currentState = GAME_STATES.PAUSE
                clearInterval(this.gameInterval)
            }
        },

        reset() {
            this.playerOneScore = 0
            this.playerTwoScore = 0
            this.difficultyLevel = 1
            this.ballPositionX = this.$canvas.width/2 - this.ballSize/2;
            this.ballPositionY = this.$canvas.height/2 - this.ballSize/2;
            this.paddleOneY = this.$canvas.height/2 - this.paddleHeight/2;
            this.paddleTwoY = this.$canvas.height/2 - this.paddleHeight/2;
            this.ballVelocityY = this.getRandomNumber(-5,5) * (.25 * this.difficultyLevel),
            this.start();
        },

        resetBall() {
            this.ballVelocityX = -this.ballVelocityX;
            this.ballVelocityY = this.getRandomNumber(-5,5) * (.25 * this.difficultyLevel);
            this.ballPositionX = this.$canvas.width/2;
            this.ballPositionY = this.$canvas.height/2;
        },

        getBallVelocityX() {
            return this.$canvas.width < 500 ? 4 : this.$canvas.width < 1000 ? 8 : this.$canvas.width < 1500 ? 10 : 12
        },

        getRandomNumber(min, max) {
            return Math.random() * (max - min) + min;
        },

        randomizeGame() {
          this.paddleTwoVelocityY = this.getRandomNumber(10,20) * (.25 * this.difficultyLevel);
        },

        gameOver(playerWon) {
            clearInterval(this.gameInterval);
            if(playerWon) {
                this.currentState = GAME_STATES.WIN
            } else {
                this.currentState = GAME_STATES.LOSE
            }
        },

        draw() {
            this.canvasContext.clearRect(0, 0, this.$canvas.width, this.$canvas.height);

            // // Middle line
            // this.canvasContext.strokeStyle = COLORS.SCORE;
            // this.canvasContext.beginPath();
            // this.canvasContext.moveTo(this.$canvas.width/2,0);
            // this.canvasContext.lineTo(this.$canvas.width/2,this.$canvas.height);
            // this.canvasContext.stroke();

            // Scores
            this.canvasContext.fillStyle = COLORS.SCORE;
            this.canvasContext.font = "200px 'Aeonik Pro', Arial";
            this.canvasContext.textAlign = 'center';
            this.canvasContext.fillText(this.playerOneScore,this.$canvas.width*.25,this.$canvas.height/2 + 75);

            this.canvasContext.fillStyle = COLORS.SCORE;
            this.canvasContext.font = "200px 'Aeonik Pro', Arial";
            this.canvasContext.textAlign = 'center';
            this.canvasContext.fillText(this.playerTwoScore,this.$canvas.width*.75,this.$canvas.height/2 + 75);

            // Ball
            this.canvasContext.fillStyle = COLORS.ELEMENTS;
            this.canvasContext.beginPath();
            this.canvasContext.arc(this.ballPositionX, this.ballPositionY, this.ballSize/2, 0, Math.PI*2, true);
            this.canvasContext.fill();

            // Paddles
            this.canvasContext.fillStyle = COLORS.ELEMENTS;
            this.canvasContext.fillRect(0,this.paddleOneY,this.paddleWidth,this.paddleHeight); // x, y, w, h

            this.canvasContext.fillStyle = COLORS.ELEMENTS;
            this.canvasContext.fillRect(this.$canvas.width - this.paddleWidth,this.paddleTwoY,this.paddleWidth,this.paddleHeight); // x, y, w, h
        },

        moveEverything() {

            this.ballPositionX = this.ballPositionX + this.ballVelocityX;

            if(this.ballPositionX > this.$canvas.width - this.paddleWidth - this.ballSize/2) {
                if(this.ballPositionY >= this.paddleTwoY
                    && this.ballPositionY <= this.paddleTwoY + this.paddleHeight
                    && this.ballPositionX < this.$canvas.width) {

                    this.ballVelocityX = -this.ballVelocityX;

                    if(this.store.hasSoundOn) {
                        this.soundPaddle.play()
                    }

                    if(this.ballPositionY >= this.paddleTwoY
                        && this.ballPositionY < this.paddleTwoY + this.paddleHeight*.2) {
                        this.ballVelocityY = -15 * (.25 * this.difficultyLevel);
                    } else if(this.ballPositionY >= this.paddleTwoY + this.paddleHeight*.2
                        && this.ballPositionY < this.paddleTwoY + this.paddleHeight*.4) {
                        this.ballVelocityY = -10 * (.25 * this.difficultyLevel);
                    } else if(this.ballPositionY >= this.paddleTwoY + this.paddleHeight*.4
                        && this.ballPositionY < this.paddleTwoY + this.paddleHeight*.6) {
                        this.ballVelocityY = this.getRandomNumber(-5,5);
                    } else if(this.ballPositionY >= this.paddleTwoY  + this.paddleHeight*.6
                        && this.ballPositionY < this.paddleTwoY + this.paddleHeight*.8) {
                        this.ballVelocityY = 10 * (.25 * this.difficultyLevel);
                    } else if(this.ballPositionY >= this.paddleTwoY + this.paddleHeight*.8
                        && this.ballPositionY < this.paddleTwoY + this.paddleHeight) {
                        this.ballVelocityY = 15 * (.25 * this.difficultyLevel);
                    }
                } else if(this.ballPositionX > this.$canvas.width) {
                    this.resetBall();
                    this.playerOneScore++;
                    this.difficultyLevel = this.playerOneScore*.5;
                    if(this.playerOneScore === scoreToWin) {
                        this.gameOver(true);
                    } else if(this.store.hasSoundOn) {
                        this.soundPoint.play()
                    }
                }

                this.randomizeGame();

            } else if(this.ballPositionX < this.paddleWidth + this.ballSize/2) {
                if(this.ballPositionY >= this.paddleOneY
                    && this.ballPositionY <= this.paddleOneY + this.paddleHeight
                    && this.ballPositionX > this.ballSize/2) {

                    this.ballVelocityX = -this.ballVelocityX;

                    if(this.store.hasSoundOn) {
                        this.soundPaddle.play()
                    }

                    if(this.ballPositionY >= this.paddleOneY
                        && this.ballPositionY < this.paddleOneY + this.paddleHeight*.2) {
                        this.ballVelocityY = -20 * (.25 * this.difficultyLevel);
                    } else if(this.ballPositionY >= this.paddleOneY + this.paddleHeight*.2
                        && this.ballPositionY < this.paddleOneY + this.paddleHeight*.4) {
                        this.ballVelocityY = -10 * (.25 * this.difficultyLevel);
                    } else if(this.ballPositionY >= this.paddleOneY + this.paddleHeight*.4
                        && this.ballPositionY < this.paddleOneY + this.paddleHeight*.6) {
                        this.ballVelocityY = 0 * (.25 * this.difficultyLevel);
                    } else if(this.ballPositionY >= this.paddleOneY  + this.paddleHeight*.6
                        && this.ballPositionY < this.paddleOneY + this.paddleHeight*.8) {
                        this.ballVelocityY = 10 * (.25 * this.difficultyLevel);
                    } else if(this.ballPositionY >= this.paddleOneY + this.paddleHeight*.8
                        && this.ballPositionY < this.paddleOneY + this.paddleHeight) {
                        this.ballVelocityY = 20 * (.25 * this.difficultyLevel);
                    }
                } else if(this.ballPositionX <= -this.ballSize) {
                    this.resetBall();
                    this.playerTwoScore++;
                    if(this.playerTwoScore === scoreToWin) {
                        this.gameOver(false);
                    } else if(this.store.hasSoundOn) {
                        this.soundOut.play()
                    }
                }
                this.randomizeGame();
            }

            this.ballPositionY = this.ballPositionY + this.ballVelocityY;

            if(this.ballPositionY > this.$canvas.height - this.ballSize/2) {
                this.ballVelocityY = -this.ballVelocityY;
                this.ballPositionY = this.$canvas.height - this.ballSize/2;
            } else if(this.ballPositionY < this.ballSize/2) {
                this.ballVelocityY = -this.ballVelocityY;
                this.ballPositionY = this.ballSize/2;
            }

            if(this.paddleOneDirectionY > 0 && this.paddleOneY >= 0) {
                this.paddleOneY = this.paddleOneY - this.paddleOneVelocityY;
            } else if(this.paddleOneDirectionY < 0 &&
                this.paddleOneY < (this.$canvas.height - this.paddleHeight) ) {
                this.paddleOneY += this.paddleOneVelocityY;
            }

            if(this.ballPositionY < this.paddleTwoY) {
                this.paddleTwoY -= this.paddleTwoVelocityY;
            } else if(this.ballPositionY > this.paddleTwoY + this.paddleHeight) {
                this.paddleTwoY += this.paddleTwoVelocityY;
            }
        },

        moveUp(e) {
            e.preventDefault()

            if(!this.isPaused) {
                this.paddleOneDirectionY = 1
            }
        },

        moveDown(e) {
            e.preventDefault()

            if(!this.isPaused) {
                this.paddleOneDirectionY = -1
            }
        },

        keyDown(e) {
            const code = e.keyCode

            if(code === 13 && this.isPlaying) {
                this.pause();
            } else if(code === 38) {
                e.preventDefault()

                if(!this.isPaused) {
                    this.paddleOneDirectionY = 1
                }
            } else if(code === 40) {
                e.preventDefault()

                if(!this.isPaused) {
                    this.paddleOneDirectionY = -1
                }
            }
        },

        keyUp() {
            this.paddleOneDirectionY = 0
        },

        resize() {
            this.resetBall();
            this.$canvas.width = this.$canvas.offsetWidth;
            this.$canvas.height = this.$canvas.offsetHeight;
            this.ballVelocityX = this.getBallVelocityX()
            this.draw();
        },

        blur() {
            if(this.isPlaying) {
                this.pause()
            }
        }
    },
    computed: {
        isToStart() {
            return this.currentState === GAME_STATES.START
        },
        isPaused() {
            return this.currentState === GAME_STATES.PAUSE
        },
        isPlaying() {
            return this.currentState === GAME_STATES.PLAYING
        },
        isWon() {
            return this.currentState === GAME_STATES.WIN
        },
        isLost() {
            return this.currentState === GAME_STATES.LOSE
        },
        isEnded() {
            return this.currentState === GAME_STATES.WIN || this.currentState === GAME_STATES.LOSE
        }
    },
    watch: {
        currentState() {
            if(!this.store.hasSoundOn) {
                return
            }

            if(this.isWon) {
                this.soundWin.play()
            } else if(this.isLost) {
                this.soundLose.play()
            }
        }
    },
    unmounted() {
        clearInterval(this.gameInterval)
        document.removeEventListener('keydown', this.onKeyDown)
        document.removeEventListener('keyup', this.onKeyUp)
        window.removeEventListener('resizeEnd', this.onResize)
        window.removeEventListener('blur', this.onBlur)
    }
}
</script>

<style lang="scss">

.page-play {

    .o-laser,
    .c-scene,
    .c-header_nav,
    .c-header_button {
        display: none;
    }
}

.c-play {
    --play-margin: 12px;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: vh(100);
    padding: rem(80px) var(--grid-gutter) var(--grid-gutter);
    text-align: center;
}

.c-play_title {
    position: absolute;
    top: var(--grid-gutter);
    left: calc(50% - 10em/2);
    width: 10em;
}

.c-play_content {
    z-index: 1;
    position: absolute;
    top: var(--header-height);
    bottom: var(--header-height);
    left: 0;
    width: 100%;
    height: auto;

    .c-play.is-playing & {
        pointer-events: none;
    }
}

.c-play_headline {
    position: absolute;
    bottom: 50%;
    left: calc(50% - #{rem(200px)}/2);
    display: block;
    width: rem(200px);
}

.c-play_instructions {

    @media (hover:none) {
        display: none;
        visibility: hidden;
    }
}

.c-play_buttons {
    position: absolute;
    top: calc(50% + #{rem(20px)});
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    opacity: 0;
    transform: translate(0, 50%);
    transition: opacity $speed $easing, transform $speed $easing;

    &:not(.is-visible) {
        pointer-events: none;
    }

    html.is-ready &.is-visible {
        opacity: 1;
        transform: translate(0);
        transition: opacity $speed-slow $easing $speed, transform $speed-slow $easing $speed;
    }
}

.c-play_button {
    margin-right: var(--grid-gutter-half);
    margin-left: var(--grid-gutter-half);

    @media (max-width: $to-tiny) {
        min-width: grid-space(4/10);
    }

    @media (min-width: $from-tiny) and (max-width: $to-small) {
        min-width: grid-space(3/10);
    }

    @media (min-width: $from-small) and (max-width: $to-large) {
        min-width: grid-space(4/16);
    }

    @media (min-width: $from-large) {
        min-width: grid-space(2/16);
    }
}

.c-play_inner {
    // position: absolute;
    // top: calc(#{rem(80px)} + var(--grid-gutter));
    // bottom: var(--grid-gutter);
    // left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: opacity $speed $easing;

    html.is-ready & {
        opacity: 1;
        transition: opacity $speed-slow $easing $speed;
    }
}

.c-play_canvas {
    width: 100%;
    height: 100%;
    transition: opacity $speed-slow $easing;

    .c-play:not(.is-playing) & {
        opacity: .25;
    }
}

.c-play_nav {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    padding-top: var(--grid-gutter);
    opacity: 0;
    transform: translate(0, 50%);
    transition: opacity $speed-slow $easing, transform $speed-slow $easing;

    .c-play:not(.is-playing) & {
        opacity: .25;
    }

    html.is-ready & {
        opacity: 1;
        transform: translate(0);
    }

    .c-button {
        width: rem(100px);
    }

    @media(hover:hover) {
        display: none;
        visibility: hidden;
    }
}

</style>
